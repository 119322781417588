/*
@license

dhtmlxDiagram v.2.1.0 Trial
This software is covered by DHTMLX Evaluation License. Contact sales@dhtmlx.com to get Commercial or Enterprise license. Usage without proper license is prohibited.

(c) Dinamenta, UAB.
*/
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
.dhx_btn,
.dhx_btn_small {
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 6px 12px;
  border-radius: 2px;
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
}
.dhx_btn .dxi,
.dhx_btn_small .dxi {
  font-size: 20px;
  margin-right: 4px;
  margin-left: -4px;
}
.dhx_btn_small span,
.dhx_btn span {
  line-height: 24px;
  position: relative;
  top: 1px;
}
.dhx_btn:hover,
.dhx_btn_small:hover {
  color: #fff;
  background: #0278b8;
  cursor: pointer;
}
.dhx_btn .action,
.dhx_btn:active,
.dhx_btn_small .action,
.dhx_btn_small:active {
  background: #0278b8;
}
.dhx_btn--active {
  background: #0288d1;
  color: #fff;
}
.dhx_btn--active:hover {
  color: #fff;
  background: #0278b8;
  cursor: pointer;
}
.dhx_btn--flat {
  border: none;
  background: transparent;
  color: #015785;
}
.dhx_btn--flat:hover {
  background: rgba(2, 136, 209, 0.2);
  color: #015785;
  cursor: pointer;
}
.dhx_btn_small {
  padding: 4px 8px !important;
  font-size: 14px;
  line-height: 20px;
}
.dhx_widget,
.dhx_widget button,
.dhx_widget input,
.dhx_widget select,
.dhx_widget textarea {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dhx_widget button {
  font-weight: 500;
}
.dhx_widget .dxi,
.dhx_widget .fas,
.dhx_widget .mdi {
  font-size: 24px;
}
.dhx_noselect {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.dhx_widget input:focus::-webkit-input-placeholder {
  color: transparent;
}
.dhx_widget input:focus:-moz-placeholder,
.dhx_widget input:focus::-moz-placeholder {
  color: transparent;
}
.dhx_widget input:focus:-ms-input-placeholder {
  color: transparent;
}
.dhx_popup_toolbar {
  position: absolute;
  z-index: 1;
  animation: a 0.2s ease-in-out forwards;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
}
.dhx_popup_toolbar.hide {
  animation: b 0.2s ease-in-out forwards;
}
.dhx_item_toolbar {
  display: flex;
  height: 48px;
  padding: 0 8px;
  align-items: center;
}
.dhx_item_toolbar .dhx_icon {
  display: flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: 50%;
  justify-content: center;
  align-items: center;
}
.dhx_item_toolbar .dhx_icon:hover {
  background-color: #f7f7f7;
}
.dhx_item_toolbar .dhx_icon:hover svg {
  fill: #0288d1;
}
.dhx_item_toolbar .dhx_icon:hover.dhx_remove svg {
  fill: #e10000;
}
.dhx_item_toolbar .dhx_icon.dhx_active svg {
  fill: #0288d1;
}
.dhx_item_toolbar .dhx_icon svg {
  fill: #8792a7;
}
.dhx_item_toolbar .dhx_remove:hover {
  background-color: #fff;
}
@keyframes a {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes b {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(30%);
    opacity: 0;
  }
}
.dhx_diagram {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: auto;
  font-size-adjust: 100%;
  text-align: center;
}
.dhx_diagram .dhx_wrapper {
  display: inline-block;
  position: relative;
}
.dhx_diagram_connector {
  stroke: #ccc;
  fill: none;
}
.dhx_diagram_item .dhx_hide_icon {
  display: none;
  user-select: none;
  shape-rendering: auto;
}
.dhx_diagram_item .dhx_expand_icon {
  user-select: none;
  shape-rendering: auto;
  cursor: pointer;
}
.dhx_diagram_item:hover .dhx_hide_icon {
  display: block;
  animation: c 0.5s ease-out;
  cursor: pointer;
}
.dhx_diagram_item .dhx_item_shape {
  fill: #fff;
}
.dhx_diagram_item .shape_content {
  display: table-cell;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}
.dhx_diagram_item.dhx_selected .dhx_item_shape {
  fill: #fff;
}
.dhx_diagram_item.dhx_diagram_image .shape_content {
  display: block;
  overflow: hidden;
  text-align: left;
}
.dhx_diagram_item.dhx_diagram_image .dhx_content_img {
  float: left;
  width: 60px;
  height: 60px;
  margin: 16px 12px 0 8px;
  border-radius: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
}
.dhx_diagram_item.dhx_diagram_image .dhx_content_text {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dhx_diagram_item.dhx_diagram_image .dhx_content_text-alone {
  margin-top: 12px;
}
.dhx_diagram_item.dhx_diagram_image .dhx_content_title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  line-height: 17px;
  margin-top: 25px;
  white-space: nowrap;
  text-transform: uppercase;
}
.dhx_diagram_item.dhx_diagram_svg-card .dhx_content_text {
  fill: rgba(0, 0, 0, 0.7);
}
.dhx_diagram_item.dhx_diagram_svg-card .dhx_content_title {
  font-weight: 500;
  text-transform: uppercase;
  fill: rgba(0, 0, 0, 0.38);
}
.dhx_free_diagram {
  background: #fff;
}
.dhx_free_diagram .dhx_diagram_connector {
  stroke: #2196f3;
  stroke-width: 2px;
}
.dhx_free_diagram .dhx_diagram_extra_lines {
  stroke-width: 2px;
}
@keyframes c {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.dhx_container {
  margin: -20px;
  user-select: none;
}

.dhx_diagram.dhx_widget {
  height: initial !important;
  flex: 1 auto;
  background-color: initial;

  min-height: calc(
    100vh - 80px - (19px + 30px + 20px) - (61px + 20px) - (24px + 30px - 20px)
  ); /* viewport height - layout top padding - (breadcrumbs height + padding + margin) - (header row height + margin ) - (footer height + padding - margin) */
  max-height: calc(
    100vh - 80px - (19px + 30px + 20px) - (61px + 20px) - (24px + 30px - 20px)
  ); /* viewport height - layout top padding - (breadcrumbs height + padding + margin) - (header row height + margin ) - (footer height + padding - margin) */
  overflow: auto;
}

.dhx_diagram_item.company .dhx_item_shape,
.dhx_diagram_item.manager .dhx_item_shape {
  fill: #0374d3;
}

.dhx_diagram_item.contractor .dhx_item_shape {
  fill: #E4E4E4;
}

.dhx_diagram_item.company .dhx_content_img,
.dhx_diagram_item.organizationunit .dhx_content_img {
  display: none;
}

.dhx_diagram_item.company .dhx_content_text-alone,
.dhx_diagram_item.organizationunit .dhx_content_text-alone {
  text-align: center;
}

.dhx_diagram_item.company .dhx_item_header {
  fill: none;
  stroke: none;
}

.dhx_diagram_item.company .shape_content,
.dhx_diagram_item.company .dhx_content_text,
.dhx_diagram_item.manager .shape_content,
.dhx_diagram_item.manager .dhx_content_text,
.dhx_diagram_item.manager .dhx_content_title {
  color: white;
  font-weight: bold;
}

.dhx_diagram_item.contractor .shape_content,
.dhx_diagram_item.contractor .dhx_content_text,
.dhx_diagram_item.contractor .dhx_content_title {
  font-weight: bold;
}

.dhx_diagram_item.clickable {
  cursor: pointer;
}

.dhx_diagram_item.job_title .dhx_content_text {
  font-size: 11px;
}

.dhx_diagram_item.organizationunit .dhx_item_header {
  fill: none;
  stroke: none;
}
