.emankit__login {
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 80px 0px !important;
  height: 377px;

  @media (max-width: 632px) {
    max-width: none;
    width: 100%;
  }

  &__logo {
    background-image: url("./logo.svg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 60px;
    height: 60px;
  }

  &__company {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 120px;

    &-label {
      font-size: 36px;
    }
  }
}
