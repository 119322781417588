$headerHeight: 40px;
$sidebarWidth: 250px;
$blue: #0092e0;
$grey: #e7e9eb;
$black: #13293d;

.top-margin-220 {
  margin-top: 220px;
}

.allocation-table {
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  //height: calc(100vh - 350px);

  .today-pointer {
    position: absolute;
    height: 9999px;
    width: 2px;
    background-color: $black;
    margin-top: 50px;
  }

  * {
    box-sizing: border-box;
  }

  table {
    width: 100%;

    td {
      border-right: 1px solid $grey;
    }
  }

  .sidebar {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f9f9fa;
    min-width: $sidebarWidth;
    max-width: $sidebarWidth;
    width: $sidebarWidth;

    .employee-calendar-sort {
      width: 99.5%;
      text-align: center;
    }
  }

  .hoverbox {
    background-color: #fff;
    position: absolute;
    max-width: 400px;
    z-index: 300;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
    padding: 10px;
  }

  .header {
    height: $headerHeight;
    //position: sticky;
    //top: 0;

    .sidebar {
      border-bottom: 1px solid $grey;
    }

    .date-header {
      width: 72px;
      max-width: 72px;
      border-bottom: 1px solid $grey;

      &.active {
        color: $blue;
        border-bottom: 4px solid $blue;
      }

      &.weekend {
        color: #89949e;
      }

      .day {
        padding: 10px 20px;
        display: flex;
        width: 100%;
        height: 100%;
        line-height: 100%;
        justify-content: center;

        .date,
        .dayName {
          line-height: $headerHeight;
          vertical-align: middle;
        }

        .dayName {
          font-weight: bold;
          margin-left: 5px;
        }
      }
      .day-is-month {
        flex-direction: column-reverse;
        align-items: flex-end;
        padding: 0px 20px !important;
      }
    }

    .is-month {
      width: 35px !important;
      max-width: 35px;
    }
  }

  .group {
    .table-row:last-child {
      .table-col {
        border-bottom: 1px solid $grey;
      }
    }

    .double-height {
      height: 60px !important;
      .text {
        display: flex !important;
        align-items: center;
      }
    }

    .table-col {
      height: 100%;
      padding: 3px;
      touch-action: none;

      .cells-margin {
        margin-top: 8px;
      }

      .allocation-table-item {
        color: #ffffff;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        vertical-align: middle;
        padding-left: 8px;
        word-wrap: break-word;
        text-overflow: ellipsis;

        .text {
          display: inline-block;
          font-size: 14px;
          line-height: 100%;
          height: 100%;
        }

        &.balance-item {
          font-weight: bold;
          cursor: default;

          &.full {
            background-color: #e4f3e6;
            color: #2dc337;
            font-weight: normal;
          }

          &.wrong-full {
            background-color: #fff0d9;
            color: #f39214;
            font-weight: normal;
          }

          &.free {
            background-color: #2dc337;
          }

          &.over {
            background-color: #db273f;
          }

          &.off {
            background-color: #ffe9ec;
            color: #db273f;
          }
          &.disabled {
            background: repeating-linear-gradient(-45deg, #f2f2f2, #f2f2f2 5px, white 5px, white 10px);
            cursor: default;
          }
        }

        &.allocation-item {
          background-color: #0092e0;
          cursor: pointer;

          &.approved {
            background-color: #0092e0;
          }

          &.not-approved {
            background-color: #7cc6ed;
          }

          &.not-work-approved {
            background-color: #89949e;
          }

          &.not-work-not-approved {
            background-color: #c1c7cc;
          }

          &.approved-service {
            background-color: #be2edd;
          }

          &.not-approved-service {
            background-color: #da95e8;
          }

          &.problem {
            border: solid 2px #db273f;
          }

          &.free {
            background-color: #2dc337;
          }

          &.over {
            background-color: #db273f;
          }

          &.warning {
            border: solid 2px #f39214;
          }
        }
      }
    }

    .cell-allign-top {
      vertical-align: top !important;
    }

    .main-balance {
      height: 30px !important;
      line-height: 30px !important;

      .sidebar {
        font-weight: bold;
      }
    }

    .job-position,
    .vacation {
      height: 30px;
      line-height: 30px;

      .sidebar {
        font-weight: bold;
        padding-left: 20px;
      }
    }
    .job-position {
      .wrong-full {
        background-color: #e4f3e6 !important;
        color: #2dc337 !important;
      }
    }

    .project-allocation {
      height: 30px;
      line-height: 30px;

      .sidebar {
        text-decoration: underline;
        padding-left: 20px;
      }
    }

    .user-sidebar {
      height: 100%;
      line-height: 100%;
      vertical-align: middle;
      display: flex;
      position: relative;
      cursor: pointer;

      .name {
        margin-top: 10px;
        display: inline;
        line-height: 100%;
        vertical-align: middle;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .emankit__avatar {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
      }

      &:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1LjUiIHZpZXdCb3g9IjAgMCAyMCAxMSI+PHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMxMzI5M0QiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xOSAxMGwtOS05LTkgOSIvPjwvc3ZnPgo=");
        line-height: 3px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -2.75px;
        width: 10px;
        height: 5.5px;
        transition: 0.5s transform ease;
        transform: rotate(0deg);
      }
    }

    &.closed {
      .user-sidebar {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .job-sidebar {
      height: 100%;
      line-height: 100%;
      vertical-align: middle;
      display: flex;
      position: relative;
      cursor: pointer;

      .name {
        margin-top: 10px;
        display: inline;
        line-height: 100%;
        vertical-align: middle;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      .width-limit {
        max-width: 200px;
        color: $black !important;
      }

      &:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1LjUiIHZpZXdCb3g9IjAgMCAyMCAxMSI+PHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMxMzI5M0QiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xOSAxMGwtOS05LTkgOSIvPjwvc3ZnPgo=");
        line-height: 3px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -2.75px;
        width: 10px;
        height: 5.5px;
        transition: 0.5s transform ease;
        transform: rotate(180deg);
      }
    }

    .sub-closed {
      .job-sidebar {
        &:after {
          transform: rotate(0deg) !important;
        }
      }
    }
    .hide-arrow {
      &:after {
        display: none;
      }
    }
  }
}
