.working-time-ratio-slider-group {
  width: 100%;
  margin-top: 50px;
}

.working-time-ratio-sliders {
  $blue: #0092e0;
  $white: #ffffff;
  $black: #13293d;
  $grey: mix($black, $white, 10%);

  .slider-row {
    display: flex;
    align-content: space-between;
    margin-top: 10px;

    .job-title {
      width: 390px;
      line-height: 40px;
      margin-top: -5px !important;
    }

    .ratio-organization {
      margin: -10px !important;
      margin-left: 0px !important;
    }

    .slider {
      padding-top: 15px;
      width: 700px;
      text-align: right;
      margin-right: 45px;

      .rc-slider-track {
        background-color: #0092e0;
      }

      .rc-slider-handle {
        height: 20px;
        width: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border: 6px solid $white;
        background-color: $blue;
        box-shadow: 0px 1px 4px 0px $grey;

        &:hover {
          border-color: $white;
        }
      }
    }

    .ratio {
      width: 250px;
      font-weight: bold;
      position: relative;

      .percentage {
        text-align: center;
        display: inline-block;
        margin-right: 35px;
        height: 40px;
        width: 80px;
        border-radius: 20px;
        background-color: rgba(19, 41, 61, 0.03);
        line-height: 40px;
      }

      .valid {
        background-color: #2dc337 !important;
        color: white !important;
      }

      .invalid {
        background-color: #ce3647 !important;
        color: white !important;
      }

      .hours {
        text-align: right;
        display: inline-block;
        height: 40px;
        width: 26px;
        color: #89949e;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 40px;
      }
    }
  }
}
